import React, { useState, useEffect } from 'react'
import Seo from 'components/seo'
import Link from 'components/Base/Link'
import Layout from 'layouts/zh'
import Button, { ButtonGroup, ButtonType } from 'components/Base/Button'
import Card, { CardGroup } from 'components/Base/Card'
import SolutionsBanner from 'components/SolutionsBanner'
import SectionContent, { SectionHeaderMobile, SectionContentTypes } from 'components/SectionContent'
import SolutionsSwiper from 'components/SolutionsSwiper'
import SolutionsSwiperMobile from 'components/SolutionsSwiperMobile'
import { MediaSwiper } from 'components/MediaCard'
import ClientContent, { ClientTypes } from 'components/CustomerTestimony'
import { MaterialSwiper } from 'components/MaterialCard'
import StructureImage from 'components/StructureImage'
import ScanCodeConsultButton from 'components/ScanCodeConsultButton'
import Scheme from 'components/Scheme'
import PageBottomCard from 'components/PageBottomCard'
import { NavMenu, SchemeContainer } from '../iptv/index.zh'
import { isPc } from 'utils/utils'

import 'swiper/swiper.min.css'
import * as styles from './index.module.less'

import bannerIcon from 'assets/images/solution/iptvMobile/zh/banner-icon.png'

const materialList = [
  {
    imgSrc: require('assets/images/solution/iptvMobile/zh/material_01.png').default,
    title: '玩转智能推荐 驱动业务增长',
    href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3603',
    buttonText: '免费观看课程',
  },
  {
    imgSrc: require('assets/images/solution/iptvMobile/zh/material_02.png').default,
    title: '打造数字化运营闭环，赋能融合媒体智能升级',
    href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=2798',
    buttonText: '免费观看课程',
  },
  {
    imgSrc: require('assets/images/solution/iptvMobile/zh/material_03.png').default,
    title: '融合媒体行业内容智能化推荐',
    href: 'https://www.sensorsdata.cn/market/playback-detail.html?id=2879',
    buttonText: '免费观看课程',
  },
  {
    imgSrc: require('assets/images/solution/iptvMobile/zh/material_04.png').default,
    title: '用户中台建设实践解析',
    href: 'https://www.sensorsdata.cn/school/library/6ae11e9374e56d4040e38ef748d54497',
    buttonText: '免费观看课程',
  },
]

const IptvMobile: React.FC = (props) => {
  return (
    <Layout {...props}>
      <IptvMobileState />
    </Layout>
  )
}

const IptvMobileState: React.FC = (props) => {
  const demoUrl = '/doFullMediaMobile?formpage_source2=融媒移动端'
  const paradeUrl = '/form/parade.html?formpage_source2=融媒移动端'

  return (
    <>
      <Seo
        title="融合媒体｜以用户为中心的数据平台-神策数据解决方案"
        description="神策数据融合媒体解决方案，致力于打造以用户为中心的数据平台，通过大数据分析、用户运营、用户画像构建以及内容智能推荐，帮助智能大屏和移动端媒体完成数字化转型，加速传统媒体与新媒体融合发展。"
        keywords="融合媒体解决方案,数字化转型,IPTV,智能大屏,移动端媒体"
        saTitle="首页-产品（行业解决方案）-融合媒体"
      />
      <main className={styles.IptvState}>
        <SolutionsBanner
          className={styles.IptvBanner}
          title="融合媒体移动端解决方案"
          subTitle="推动移动端媒体数字化转型，数据驱动用户精细化运营"
          desc="建设以用户为中心的数据平台，实现多端数据融合，深度洞察用户，智能匹配内容，提升用户的粘性。"
          imgUrl={<img src={bannerIcon} alt="" />}
          imgWidth={405}
          imgHeight={357}
          buttonGroup={
            <ButtonGroup>
              <Button href={demoUrl} target="_blank">
                体验移动端 Demo
              </Button>
            </ButtonGroup>
          }
          mobileHideIcon
          mobileHideButton
          mobileHideDesc
        />
        <NavMenu
          menus={[
            {
              url: '/solutions/iptv.html',
              name: '智能大屏解决方案',
            },
            {
              url: '/solutions/iptvMobile.html',
              name: '移动端解决方案',
              isActive: true,
            },
          ]}
        />
        <SectionContent title="移动端媒体大数据策略平台" type={SectionContentTypes.UseSectionHeaderMobile}>
          <SectionHeaderMobile title="智慧媒体大数据策略平台" />
          <SolutionsSwiper
            className={styles.SolutionsSwiper}
            dataSource={[
              {
                className: 'feature-01',
                icon: 'output',
                tabText: <>多维度数据分析</>,
                title: '多维度数据分析',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_01.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '全端行为还原',
                    list: ['全端数据的采集与打通，获取用户的立体化行为特征'],
                  },
                  {
                    title: '实时分析查询',
                    list: ['建设数据基础平台，千亿数据量的秒级导入和查询'],
                  },
                  {
                    title: '数据实时监管',
                    list: ['下载量、活跃用户、热门内容、CTR 等数据的实时监管，提升对用户和内容的感知'],
                  },
                ],
                buttonList: [
                  {
                    text: '移动端 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-02',
                icon: 'user-insight',
                tabText: <>用户深度洞察</>,
                title: '用户深度洞察',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_02.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '体系化标签画像',
                    list: ['根据用户的观影习惯、收视偏好、订购情况等，打造标签画像体系，深度洞察用户'],
                  },
                  {
                    title: '赋能个性化运营',
                    list: ['根据标签画像洞察，赋能用户增长、用户活跃、个性化推送等运营场景'],
                  },
                ],
                buttonList: [
                  {
                    text: '移动端 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-03',
                icon: 'optimize',
                tabText: <>产品功能优化</>,
                title: '产品功能优化',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_03.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '用户交互调优',
                    list: ['通过改变购买 App 界面的样式，让用户更可能进行下一步操作，从而提高当前环节转化率'],
                  },
                  {
                    title: '推荐位调优',
                    list: ['通过改变推荐策略，让用户更容易看到自己喜欢的商品，从而提升人均观影时长/完播率'],
                  },
                ],
                buttonList: [
                  {
                    text: '移动端 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
              {
                className: 'feature-04',
                icon: 'content-recommend',
                tabText: <>内容智能推荐</>,
                title: '内容智能推荐',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_04.png').default,
                imgWidth: 630,
                descList: [
                  {
                    title: '智能推荐方案',
                    list: ['以效果提升为交付目标，打造“数据-算法-效果-优化”一站式的闭环推荐方案'],
                  },
                  {
                    title: '千人千面',
                    list: ['按用户偏好、热门、相关性、地理位置、栏目等维度进行推荐，提高用户黏性和用户活跃'],
                  },
                ],
                buttonList: [
                  {
                    text: '移动端 Demo',
                    href: demoUrl,
                    target: '_blank',
                    type: 'primary',
                  },
                ],
              },
            ]}
          />
          {/* 移动端 */}
          <SolutionsSwiperMobile
            className={styles.SolutionsSwiperMobile}
            dataSource={[
              {
                title: '多维度数据分析',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_01.png').default,
              },
              {
                title: '深度用户洞察',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_02.png').default,
              },
              {
                title: '产品功能优化',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_03.png').default,
              },
              {
                title: '内容智能推荐',
                imgUrl: require('assets/images/solution/iptvMobile/zh/feature_04.png').default,
              },
            ]}
          />
          <ButtonGroup className={styles.SolutionsSwiperButton}>
            <Button btnType="primary" href={demoUrl}>
              移动端 Demo
            </Button>
          </ButtonGroup>
          <MediaSwiper
            className={styles.MediaSwiper}
            allowTouchMove={false}
            dataSource={[
              {
                url: 'https://www.sensorsdata.cn/market/playback-detail.html?id=3291',
                img: require('assets/images/solution/iptvMobile/zh/media-01.png').default,
                imgMobile: '',
                alt: '',
              },
            ]}
          />
        </SectionContent>
        <SectionContent
          className={styles.MaterialContainerMobile}
          title="可落地的行业实践资料"
          type={SectionContentTypes.UseSectionHeaderMobile}
        >
          <SectionHeaderMobile title="可落地的行业实践资料" />
          <CardGroup className={styles.MaterialBookWrap}>
            <Card className={styles.BookCard} title="《后广告时代数据助力融合媒体用户收入增长》" bordered={false}>
              <a href="https://www.sensorsdata.cn/market/playback-detail.html?id=3291">
                <img
                  className={styles.BookThumb}
                  src={require('assets/images/solution/iptvMobile/zh/book-thumb.png').default}
                  alt=""
                />
              </a>
            </Card>
          </CardGroup>
          <MaterialSwiper className={styles.MaterialSwiper} dataSource={materialList} />
        </SectionContent>
        <SectionContent
          title="众多行业客户服务经验"
          className={styles.ClientContainer}
          type={SectionContentTypes.UseSectionHeaderMobile}
        >
          <SectionHeaderMobile title="众多行业客户服务经验" />
          <ClientContent
            type={ClientTypes.Type20211220}
            className={styles.ClientWrap}
            abstract={
              <>
                神策数据融合媒体事业部已服务 20 余家融媒行业客户，其中省级广电机构超过 10 家。涵盖了
                OTT、IPTV、有线电视、广电报业融媒体以及图书出版等细分赛道。
                <br />
                神策数据推出的“智能广电大数据策略平台”获得北京市广播电视局“2021
                年度媒体融合创新技术与服务应用优秀推荐项目”，并通过大数据产业生态联盟“第十期优秀大数据解决方案和应用案例评测”。
              </>
            }
            // title="新周刊杂志社 副社长 梁磊"
            // desc="神策提供的灵活分析模型和强大的可视化界面，使得内容运营人员不需要很深的数据分析功底，就可以实现多维度分析、一站式数据化运营。神策团队提供的优秀服务也能帮助我们对当前已有的业务进行了更好的数据统计需求整理，降低了初次对接时产研团队的难度。新周刊 APP 以新锐观点为特色，以原创优质内容为根本，为用户提供具有思想价值和新锐生活方式的优质内容而努力，神策数据团队的服务，让我们感受到了相似的价值观，希望神策数据越来越好。"
            // buttonText="体验移动端 Demo"
            // buttonHref={demoUrl}
            // logoUrl={require('assets/images/solution/iptvMobile/zh/neweekly.png').default}
            // logoWidth={112}
            // logoWall={require('assets/images/solution/iptvMobile/zh/logo-wall.png').default}
          >
            <div className="text-center">
              <img
                className="max-w-[1000px] hidden lg:inline"
                src="https://ow-file.sensorsdata.cn/www/solutions/iptv/iptvMobile.png"
                alt=""
              />
              <p className="lg:pt-[40px] pt-[2rem]">
                <Button href={demoUrl} target="_blank" btnType="primary">
                  体验移动端 Demo
                </Button>
              </p>
            </div>
          </ClientContent>
        </SectionContent>
        <SectionContent className={styles.MaterialContainer} title="可落地的行业实践资料" backgroundColor="#F5FBFF">
          <MaterialSwiper className={styles.MaterialSwiper} dataSource={materialList} />
        </SectionContent>
        <SectionContent className={styles.StructureContainer} title="移动端媒体大数据策略平台架构图">
          <StructureImage
            className={styles.StructureImage}
            src={require('assets/images/solution/iptvMobile/zh/structure-image.png').default}
            width={1092}
          />
          <div className={styles.StructureButtonWrap}>
            <ScanCodeConsultButton
              buttonText="扫码咨询方案顾问"
              qrcode={require('assets/images/solution/game/panorama_code.png').default}
            />
            <a className={styles.linkBtn} target="_blank" href="https://www.sensorsdata.cn/democenter/media.html">
              <span className={styles.textBtn}>场景解读</span>
            </a>
          </div>
        </SectionContent>
        {/* 方案优势 在 src/pages/iptv/index.tsx 中修改 */}
        <SchemeContainer className={styles.SchemeContainer} />
        <PageBottomCard
          title="打造移动端媒体大数据策略平台！"
          desc="立即预约，快速找到适合您的移动端解决方案！"
          leftButtonText="体验移动端 Demo"
          leftButtonHref={demoUrl}
          rightButtonText="预约演示"
          rightButtonHref={paradeUrl}
        />
      </main>
    </>
  )
}

export default IptvMobile
