// extracted by mini-css-extract-plugin
export var BookCard = "index-module--BookCard--iBo3B";
export var BookThumb = "index-module--BookThumb--DRRZo";
export var ClientContainer = "index-module--ClientContainer--tbscb";
export var ClientWrap = "index-module--ClientWrap--aOa+t";
export var IptvBanner = "index-module--IptvBanner--udi8+";
export var IptvState = "index-module--IptvState--TgA1F";
export var MaterialBookWrap = "index-module--MaterialBookWrap--kq0hf";
export var MaterialContainer = "index-module--MaterialContainer--IEYdD";
export var MaterialContainerMobile = "index-module--MaterialContainerMobile--JojNZ";
export var MaterialSwiper = "index-module--MaterialSwiper--ASwfN";
export var MediaSwiper = "index-module--MediaSwiper--rk4Pc";
export var NavMenu = "index-module--NavMenu--94-Pm";
export var SchemeContainer = "index-module--SchemeContainer--NL6W6";
export var SolutionsSwiper = "index-module--SolutionsSwiper--JI2Oi";
export var SolutionsSwiperButton = "index-module--SolutionsSwiperButton--1Ipw3";
export var SolutionsSwiperMobile = "index-module--SolutionsSwiperMobile--v8Foe";
export var StructureButtonWrap = "index-module--StructureButtonWrap--o2Xsz";
export var StructureContainer = "index-module--StructureContainer--nvcTU";
export var StructureImage = "index-module--StructureImage--xQzPo";
export var active = "index-module--active--TG4pm";
export var linkBtn = "index-module--linkBtn--8bhAq";
export var textBtn = "index-module--textBtn--SN-fr";